import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { intialLoading, setIndex } from "../../store/screentransistion/actions";
import { saveUserDetails } from "../../store/trackinguser/actions";
import Layout from "../common/layoutred";
import LandingImageLogo from "../../assets/images/LOGO.png";
import alfie_logo from "../../assets/short-game-assets/alfie_logo.png";

import { fetchShuffle } from "../../store/shuffledata/operations";
import { Alert } from "@material-ui/lab";
import { isAndroid } from "react-device-detect";
import fullScreen from "../../utils/fullScreen";
import { connect } from "react-redux";
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import {
	toggleMusic,
	toggleVoiceOver,
} from "../../store/screentransistion/actions";
import music from "../../assets/audio/BGMusic.mp3";
import PlayMusic from "../../utils/playMusic";
import { saveAnalytics } from "../../store/trackinguser/operations";

import "./style.scss";
import { BROWSER_DATA } from "../../store/types";

window.gtag("event", "REGISTRATION", {
	event_category: "REGISTRATION",
	event_label: "REGISTRATION",
});

const Registration = ({
	// fetchShuffle,
	shuffleData,
	progress,
	languages,
	zones,
	saveUserDetails,
	audio,
	intialLoading,
	setIndex,
	gameSessionId,
	language,
	toggleMusic,
	toggleVoiceOver,
	tokenValidation,
}) => {
	const dispatch = useDispatch();

	const [india] = zones;
	// const [english] = languages;
	const lang = languages.find((l) => l.value == "km");
	// const english = languages.find((l) => l.value == "km");

	const [data, setData] = useState({
		zone: india._id,
		lang: lang.value,
		number: "",
	});

	const activate = fullScreen();

	const [error, setError] = useState(false);
	const [msg, setMsg] = useState("Please enter valid details");

	const handleChange = (event) => {
		const { name, value } = event.target;
		setData({ ...data, [name]: value });
		dispatch({
			type: BROWSER_DATA,
			payLoad: { ...data, lang: event.target.value },
		});
	};

	// useEffect(()=>{
	//   saveAnalytics({screenID:"USER_AT_REGISTRATION"}, () => {}, () => {});
	// },[])

	useEffect(() => {
		const path = window.location.pathname;
		const number = path.replace("/", "");
		setData({ ...data, number: number });
		dispatch({
			type: BROWSER_DATA,
			payLoad: { ...data, number: number },
		});
	}, []);

	const register = (event) => {
		event.preventDefault();
		if (
			data.number === "" ||
			data.number === undefined ||
			data.number === null ||
			data.number === "/_" ||
			data.number === "/" ||
			data.number === `/''` ||
			data.number === `/'%20'`
		) {
			setError(true);

			return;
		}
		if (data.lang && data.zone && data.number) {
			// saveAnalytics(
			// 	{ screenID: "USER_AT_REGISTRATION" },
			// 	() => {},
			// 	() => {}
			// );
			if (isAndroid) {
				activate();
			}
			intialLoading();
		} else {
			setError(true);
			setMsg("Invalid Token");
		}
	};

	return (
		<>
			<Layout>
				<div className="registration__container">
					<form onSubmit={register}>
						<div className="form_control landing_container">
							<img
								src={LandingImageLogo}
								alt="landing_image"
								className="landing_content_image"
							/>
						</div>
						{/* <FormControl
							variant="outlined"
							size="small"
							style={{
								width: "100%",
								marginTop: "20px",
								backgroundColor: "white",
							}}>
							<Select
								id="simple-select"
								name="lang"
								value={data.lang}
								onChange={handleChange}
								style={{
									fontSize: 18,
								}}>
								{languages
									? languages.map((lan) => (
											<MenuItem key={lan.value} value={lan.value}>
												{lan.language}
											</MenuItem>
									  ))
									: null}
							</Select>
						</FormControl> */}

						<div className="_button_container">
							<Button
								type="submit"
								variant="contained"
								className="_button"
								style={{ width: "100%" }}>
								{data.lang == "en" ? "START" : "ចាប់ផ្តើម"}
							</Button>
						</div>

						<div className="form__control error__msg">
							{error ? (
								<Alert severity="error">
									<h3>{msg}</h3>
								</Alert>
							) : null}
						</div>
					</form>
				</div>
			</Layout>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		shuffleData: state.shuffleData,
		progress: state.progress,
		languages: state.intialize.languages,
		zones: state.intialize.zones,
		audio: state.shuffleData,
		language: state.trackingUser.userDeatils,
		tokenValidation: state.intialize.tokenValidation,
	};
};

const mapDispathToProps = (dispatch) => {
	return {
		fetchShuffle: (language, success, failure) =>
			dispatch(fetchShuffle(language, success, failure)),
		saveUserDetails: (details) => dispatch(saveUserDetails(details)),
		intialLoading: () => dispatch(intialLoading()),
		setIndex: (index) => dispatch(setIndex(index)),
		toggleMusic: (toggle) => dispatch(toggleMusic(toggle)),
		toggleVoiceOver: (toggle) => dispatch(toggleVoiceOver(toggle)),
	};
};

export default connect(mapStateToProps, mapDispathToProps)(Registration);
