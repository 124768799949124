import axios from "axios";

export let headers = {
  "Tenant-Access-Token": "NTY1NzJmOWZkNmQ0Njc0NTA1YzdhYzg2MzVlMGMxNjE=",
  "Tenant-Access-Key": "QnOWZ70iydA3kQ",
};
export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
  baseURL: BASE_URL,
  headers,
});

